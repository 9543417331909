var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament2$ranki, _vm$tournament2$ranki2, _vm$tournament3, _vm$tournament3$ranki, _vm$tournament4, _vm$tournament5, _vm$tournament5$ranki, _vm$tournament5$ranki2, _vm$tournament6, _vm$tournament6$ranki, _vm$tournament7, _vm$tournament8, _vm$tournament8$ranki, _vm$tournament8$ranki2, _vm$tournament9, _vm$tournament9$ranki, _vm$tournament10;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "standings-view-container"
  }, [((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.status_id) >= _vm.TournamentStatusType.FINISHED ? _c('b-row', [_c('b-col', {}, [_c('h5', {
    staticClass: "m-0 mb-2"
  }, [_vm._v(" Final results ")]), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-2"
  }, [_c('span', [_c('b-img', {
    attrs: {
      "src": require("@/assets/images/icons/1stplace.png"),
      "alt": "1st place",
      "height": "35"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bold ml-2 font-medium-2"
  }, [_vm._v(_vm._s(((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : (_vm$tournament2$ranki = _vm$tournament2.rankings[0].participant) === null || _vm$tournament2$ranki === void 0 ? void 0 : (_vm$tournament2$ranki2 = _vm$tournament2$ranki.participant) === null || _vm$tournament2$ranki2 === void 0 ? void 0 : _vm$tournament2$ranki2.name) || ((_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : (_vm$tournament3$ranki = _vm$tournament3.rankings[0].participant) === null || _vm$tournament3$ranki === void 0 ? void 0 : _vm$tournament3$ranki.name)))])])]), ((_vm$tournament4 = _vm.tournament) === null || _vm$tournament4 === void 0 ? void 0 : _vm$tournament4.rankings.length) >= 2 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-2"
  }, [_c('span', [_c('b-img', {
    attrs: {
      "src": require("@/assets/images/icons/2place.png"),
      "alt": "2nd place",
      "height": "35"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bold ml-2 font-medium-2"
  }, [_vm._v(" " + _vm._s(((_vm$tournament5 = _vm.tournament) === null || _vm$tournament5 === void 0 ? void 0 : (_vm$tournament5$ranki = _vm$tournament5.rankings[1].participant) === null || _vm$tournament5$ranki === void 0 ? void 0 : (_vm$tournament5$ranki2 = _vm$tournament5$ranki.participant) === null || _vm$tournament5$ranki2 === void 0 ? void 0 : _vm$tournament5$ranki2.name) || ((_vm$tournament6 = _vm.tournament) === null || _vm$tournament6 === void 0 ? void 0 : (_vm$tournament6$ranki = _vm$tournament6.rankings[1].participant) === null || _vm$tournament6$ranki === void 0 ? void 0 : _vm$tournament6$ranki.name)) + " ")])])]) : _vm._e(), ((_vm$tournament7 = _vm.tournament) === null || _vm$tournament7 === void 0 ? void 0 : _vm$tournament7.rankings.length) >= 3 ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-2"
  }, [_c('span', [_c('b-img', {
    attrs: {
      "src": require("@/assets/images/icons/3place.png"),
      "alt": "3rd place",
      "height": "35"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bold ml-2 font-medium-2"
  }, [_vm._v(" " + _vm._s(((_vm$tournament8 = _vm.tournament) === null || _vm$tournament8 === void 0 ? void 0 : (_vm$tournament8$ranki = _vm$tournament8.rankings[2].participant) === null || _vm$tournament8$ranki === void 0 ? void 0 : (_vm$tournament8$ranki2 = _vm$tournament8$ranki.participant) === null || _vm$tournament8$ranki2 === void 0 ? void 0 : _vm$tournament8$ranki2.name) || ((_vm$tournament9 = _vm.tournament) === null || _vm$tournament9 === void 0 ? void 0 : (_vm$tournament9$ranki = _vm$tournament9.rankings[2].participant) === null || _vm$tournament9$ranki === void 0 ? void 0 : _vm$tournament9$ranki.name)) + " ")])])]) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {}, [_c('b-card', [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" Standings ")])])], 1)], 1), _c('b-row', {
    class: ((_vm$tournament10 = _vm.tournament) === null || _vm$tournament10 === void 0 ? void 0 : _vm$tournament10.status_id) >= _vm.TournamentStatusType.FINISHED ? 'scroll-container final-results' : 'scroll-container'
  }, [_c('b-col', {
    staticClass: "h-100"
  }, [_c('vue-perfect-scrollbar', {
    staticClass: "h-100",
    attrs: {
      "settings": {
        suppressScrollX: true
      }
    }
  }, [_c('b-table', {
    ref: "refParticipantsListTable",
    staticClass: "mb-0 h-100",
    attrs: {
      "fields": _vm.standingsTableCols,
      "items": _vm.standings,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.isSortDirDesc,
      "empty-text": "No standings yet",
      "primary-key": "id",
      "show-empty": "",
      "table-class": "h-100"
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.participantName(data.item)) + " ")];
      }
    }, {
      key: "cell(rank)",
      fn: function fn(data) {
        var _vm$tournament11, _vm$tournament12, _vm$tournament13;
        return [data.index === 0 && ((_vm$tournament11 = _vm.tournament) === null || _vm$tournament11 === void 0 ? void 0 : _vm$tournament11.status_id) >= _vm.TournamentStatusType.FINISHED ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/1stplace.png"),
            "alt": "1st place",
            "height": "20"
          }
        })] : data.index === 1 && ((_vm$tournament12 = _vm.tournament) === null || _vm$tournament12 === void 0 ? void 0 : _vm$tournament12.status_id) >= _vm.TournamentStatusType.FINISHED ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/2place.png"),
            "alt": "2nd place",
            "height": "20"
          }
        })] : data.index === 2 && data.item.elapsed_time !== null && ((_vm$tournament13 = _vm.tournament) === null || _vm$tournament13 === void 0 ? void 0 : _vm$tournament13.status_id) >= _vm.TournamentStatusType.FINISHED ? [_c('b-img', {
          attrs: {
            "src": require("@/assets/images/icons/3place.png"),
            "alt": "3rd place",
            "height": "20"
          }
        })] : [_vm._v(" " + _vm._s(data.index + 1) + " ")]];
      }
    }, {
      key: "cell(elapsed_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.elapsed_time ? _vm.millisecondsToTime(data.item.elapsed_time) : 'no result yet') + " ")];
      }
    }, {
      key: "cell(difference)",
      fn: function fn(data) {
        return [data.index === 0 ? [_vm._v(" " + _vm._s('--:--:--.--') + " ")] : [_vm._v(" " + _vm._s(data.item.difference ? _vm.millisecondsToTime(data.item.difference) : '') + " ")]];
      }
    }, {
      key: "cell(gap)",
      fn: function fn(data) {
        return [data.index === 0 ? [_vm._v(" " + _vm._s('--:--:--.--') + " ")] : [_vm._v(" " + _vm._s(data.item.gap ? _vm.millisecondsToTime(data.item.gap) : '') + " ")]];
      }
    }, {
      key: "cell(match_history)",
      fn: function fn(data) {
        return _vm._l(_vm.winHistory(data.item.participant_id), function (_ref, idx) {
          var matchId = _ref.matchId,
            result = _ref.result;
          return _c('b-badge', {
            key: idx,
            staticClass: "mt-1 mr-1 cursor-pointer result align-content-center",
            attrs: {
              "variant": result === 'W' ? 'light-success' : 'light-danger'
            },
            on: {
              "click": function click() {
                _vm.handleMatchInfoModalShow(matchId);
              }
            }
          }, [_vm._v(_vm._s(result))]);
        });
      }
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-2"
        }), _c('strong', [_vm._v("Loading...")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('match-info-modal', {
    attrs: {
      "match": _vm.selectedMatch
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }